
import {BrowserRouter ,Route,Routes} from "react-router-dom"
import Admin from './pages/admin/index'
import Login from './pages/Login/index'
import Home from './pages/Home/index'
import User from './pages/User/index'
import Menu from './pages/Menu/index'
import AdminImg from "./pages/admin/adminImg"
function App() {
  return (
   <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/admin/dashboard' element={<Admin/>} />
      <Route path='/admin/dashboard/img' element={<AdminImg/>} />
      <Route path='/admin/login' element={<Login/>} />
      <Route path='/user/:id' element={<User/>} />
      <Route path='/menu/:id' element={<Menu/>} />
    </Routes>
   </BrowserRouter>
  );
}

export default App;
