import React,{useState,useEffect} from 'react'
import { useNavigate ,useParams,Link} from "react-router-dom";
import Select from 'react-select'

import axios from 'axios'
import './index.scss'



const apiUrl ='https://metaserverapp.com'
export default function User() {

    const LANGUAGES=[
        {value:'English',label:'English'},
        {value: '中文',label:'中文'},
    ]

    const token = window.localStorage.getItem('menuUserToken')
    const initial ={
        name:'',
        tel:'',
        tel2:'',
        des:'',
        address:'',
        addressLink:'',
        time:[''],
        logo:'',
        category:[],
        items:[],
        language:'中文',
        userId:'',
        iframe:'',
        special:[]    }

    const CATEGORY = {
        name:'',
        des:''
    }

    const{ id } = useParams();
    // console.log(id);
    const [user,setUser]=useState({})
    const [menuInf,setMenuInf]=useState(initial)
    const [isProfile,setIsProfile]=useState(false)
    const [isDashboard,setIsDashboard]=useState(true)
    const [isCreateCategory,setIsCreateCategory]=useState(false)
    const [category,setCategory]=useState([])
    const [items,setItems]=useState([])
    const [createCategory,setCreateCategory]=useState(false)

    const [initialCategory,setInitialCategory]=useState(CATEGORY)

    const [editCategory,setEditCategory]=useState(CATEGORY)
    const [editCategoryIndex,setEditCategoryIndex]=useState(0)
    const [isEditCategory,setIsEditCategory]=useState(false)

    // 编辑分类区域

    const handleOpenEditCategory = (i)=>{
        setIsEditCategory(true)
        setEditCategory(category[i])
        setEditCategoryIndex(i)
    }

    const handleCloseEditCategory = ()=>{
        setIsEditCategory(false)

    }

    const handleEditCategoryName = (e)=>{
        let temp ={...editCategory}
        temp.name =e.target.value
        setEditCategory(temp)
    }
    const handleEditCategoryDescription = (e)=>{
        let temp ={...editCategory}
        temp.des =e.target.value
        setEditCategory(temp)
    }

    const handleSubmitEditCategory = async()=>{
        if(!editCategory.name){
            alert('Missing Category Name!')
        }
        else{
            console.log(editCategoryIndex)
            const preName = category[editCategoryIndex].name
            let temp =[...category]
            temp[editCategoryIndex]=editCategory
            console.log(editCategory,items,preName)
    
           
            const result = await axios.post(`${apiUrl}/menu/category`,{
                id:menuInf._id,
                category:temp
            })
            console.log(result);
            if(result.data.success){

                let arr = [...items]
                for(let i=0; i<arr.length; i++){
                    if(arr[i].category===preName){
                        arr[i].category = editCategory.name
                    }
                }
                console.log(arr)

                const result2 = await axios.post(`${apiUrl}/menu/items`,{
                    id:menuInf._id,
                    items:arr
                })
                console.log(result);
                if(result2.data.success){
                    setItems(arr)
                    setMenuInf(result2.data.data)
                    setCreateCategory(false)
                    setCategory(temp)
                    setInitialCategory(CATEGORY)
                    setIsEditCategory(false)
                }
                else{
                    alert('Something went wrong when edit category!' )
                }

          
            }
            else{
                alert('Something went wrong when edit category!' )
            }
            
        }
       

    }

    //


    //分类排序

    const [isOrder,setIsOrder]=useState(false)

    const handleOrder = ()=>{
        setIsOrder(true)
        setIsCreateCategory(false)
        setIsDashboard(false)
        setCategoryIndex(-1)
        setIsItem(false)
        setIsSpecialItem(false)

    }

    const handleNextCategory = async(i)=>{
        let arr = [...category]
        let temp;
        temp=arr[i+1]
        arr[i+1]=arr[i]
        arr[i]=temp

        const result = await axios.post(`${apiUrl}/menu/category`,{
            id:menuInf._id,
            category:arr
        })
        console.log(result);
        if(result.data.success){
            setCategory(arr)
            setMenuInf(result.data.data)
            setInitialCategory(CATEGORY)
        }
        else{
            alert('Something went wrong when edit category!' )
        }


    }

    const handleLastCategory = async(i)=>{
        let arr = [...category]
        let temp;
        temp=arr[i-1]
        arr[i-1]=arr[i]
        arr[i]=temp
        const result = await axios.post(`${apiUrl}/menu/category`,{
            id:menuInf._id,
            category:arr
        })
        console.log(result);
        if(result.data.success){
            setCategory(arr)
            setMenuInf(result.data.data)
            setInitialCategory(CATEGORY)
        }
        else{
            alert('Something went wrong when edit category!' )
        }

    }

    // console.log(menuInf)

    //

    // 删除分类
    const [isDeleteCategory,setIsDeleteCategory]=useState(false)
    const [deleteIndex,setDeleteIndex]=useState(0)
    const [deleteCategory,setDeleteCategory]=useState({})

    const handleOpenDeleteCategory = (i) => {
        setIsDeleteCategory(true)
        setDeleteIndex(i)
        setDeleteCategory(category[i])
        console.log(i)
    }
    const handleCancelDeleteCategory = () => {
        setIsDeleteCategory(false)

    }

    const handleSubmitDeleteCategory=async() => {

        let temp = [...category]
        temp.splice(deleteIndex,1)


        const result = await axios.post(`${apiUrl}/menu/category`,{
            id:menuInf._id,
            category:temp
        })
        console.log(result);
        if(result.data.success){
            setCategory(temp)
            setIsDeleteCategory(false)
            setMenuInf(result.data.data)
            setInitialCategory(CATEGORY)
        }
        else{
            alert('Something went wrong when edit category!' )
        }

      

    }

    //

    // 创建每日促销菜单
    const WEEK = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ]
    const SPECIAL = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
    ]
    const SPECIALITEM ={
        name:'',
        des:'',
        price:'',
        sale:'',
        img:''
    }

    const [isSpecialItem,setIsSpecialItem]=useState(false)

    const [special,setSpecial]= useState(SPECIAL)
    const [day,setDay]= useState(-1)
    const [isAddSpecial,setIsAddSpecial]=useState(false)
    const [specialItem,setSpecialItem]=useState(SPECIALITEM)

    const handleIsSpecialItem=()=>{
        setIsSpecialItem(true)
        setIsCreateCategory(false)
        setIsDashboard(false)
        setIsOrder(false)
        setIsItem(false)


    }

    const handleAddSpecialItem = (i)=>{
        setIsAddSpecial(true)
        setDay(i)
    }

    const handleCloseAddSpecialItem = ()=>{
        setIsAddSpecial(false)
        setDay(-1)
        setSpecialItem(SPECIALITEM)
    }

    const handleSpecialItemName=(e)=>{
        let temp = {...specialItem}
        temp.name = e.target.value
        setSpecialItem(temp)
    }
    const handleSpecialItemDes=(e)=>{
        let temp = {...specialItem}
        temp.des = e.target.value
        setSpecialItem(temp)
    }
    const handleSpecialItemPrice=(e)=>{
        let temp = {...specialItem}
        temp.price = e.target.value
        setSpecialItem(temp)
    }
    const handleSpecialItemSale=(e)=>{
        let temp = {...specialItem}
        temp.sale = e.target.value
        setSpecialItem(temp)
    }
    const handleSpecialItemImg=(e)=>{
        let temp = {...specialItem}
        temp.img = e.target.value
        setSpecialItem(temp)
    }

    const handleRemoveSpecialItem =async(i,n)=>{
        console.log(i,n)
        let temp = [...special]
        temp[i].splice(n, 1)
        console.log(temp)

        const result = await axios.post(`${apiUrl}/menu/special`,{
            id:menuInf._id,
            special:temp
        })
        console.log(result)
        if(result.data.success){
            alert('删除成功')
            window.location.reload()
        }
        else{
            alert('删除失败!')
            window.location.reload()

        }

    }

    const handleSubmitSpecialItem = async()=>{
        if(!specialItem.name||!specialItem.price||!specialItem.sale){
            alert("Please enter a special Item Name,Price or Sale Price")
        }else{
            let temp = [...special]
            temp[day].push(specialItem)

            const result = await axios.post(`${apiUrl}/menu/special`,{
                id:menuInf._id,
                special:temp
            })
            console.log(result)
            if(result.data.success){
                window.location.reload()
            }
            else{
                alert('Add Special Item Error!')
                window.location.reload()

            }

        }
    }


    console.log(specialItem,day)

    //编辑单个菜品

    const ITEM = {
        name:'',
        des:'',
        price:'',
        img:'',
        spicy:false,
        category:''
    }
    const SPICY=[
        {value:true,label:'Spicy'},
        {value: false,label:'Not Spicy'},
    ]

    const [initialItem,setIntialItem]= useState(ITEM)

    const [categoryIndex,setCategoryIndex]= useState(-1)
    const [isItem,setIsItem]=useState(false)
    const [openItem,setOpenItem]= useState(false)

    const [categoryItems,setCategoryItems]= useState([])

    useEffect(()=>{
        if(categoryIndex!==-1){
            const name =category[categoryIndex].name
            console.log(name);
            let temp = [...items]
            let arr=[];
            for(let i=0;i<temp.length;i++){
                if(temp[i].category===name){
                    arr.push(temp[i])
                }
            }
            setCategoryItems(arr)
        }

    },[categoryIndex,items,category])

    const handleSelectCategory = (i)=>{
        setCategoryIndex(i)
        setIsOrder(false)
        setIsCreateCategory(false)
        setIsDashboard(false)
        setIsSpecialItem(false)

        setIsItem(true)
        let temp ={...initialItem}
        temp.category = category[i].name
        setIntialItem(temp)
    }

    const handleOpenAddItem = ()=>{
        setOpenItem(true)
    }

    const handleCloseAddItem = ()=>{
        setOpenItem(false)

    }

    const handleSpicy=(e)=>{
        let temp ={...initialItem}
        temp.spicy =e.value
        setIntialItem(temp)
    }

    const handleItemName=(e)=>{
        let temp ={...initialItem}
        temp.name =e.target.value
        setIntialItem(temp)
    }
    const handleItemPrice=(e)=>{
        let temp ={...initialItem}
        temp.price =e.target.value
        setIntialItem(temp)
    }
    const handleItemDes=(e)=>{
        let temp ={...initialItem}
        temp.des =e.target.value
        setIntialItem(temp)
    }
    const handleItemImg=(e)=>{
        let temp ={...initialItem}
        temp.img =e.target.value
        setIntialItem(temp)
    }

    const handleSubmitAddItem=async()=>{

        if(!initialItem.name||!initialItem.price){
            alert('Missinig Item Name Or Price!')
        }
        else{
            let temp = [...items]
            let arr = [...categoryItems]
            temp.push(initialItem)
            arr.push(initialItem)
            console.log(temp);
            const result = await axios.post(`${apiUrl}/menu/items`,{
                id:menuInf._id,
                items:temp
            })
            console.log(result);
            if(result.data.success){
                setCategoryItems(arr)
                setItems(temp)
                setOpenItem(false)
                setMenuInf(result.data.data)
                setIntialItem(ITEM)
                window.location.reload()
            }
            else{
                alert('Something went wrong when edit category!' )
            }
    
        }
        
    }

    const [openEditItem,setOpenEditItem]= useState(false)
    const [itemIndex,setItemIndex] =useState(-1)

    const handleOpenEditItem = (v)=>{
        setOpenEditItem(true)
        setIntialItem(v)
        let temp = [...items]
           
            for(let i=0; i<temp.length; i++){
                if(temp[i].name===v.name) {
                    setItemIndex(i)
                }
            }
    }
    const handleCloseEditItem = ()=>{
        setOpenEditItem(false)
        setIntialItem(ITEM)

    }

    const handleSubmitEditItem = async()=>{
        if(!initialItem.name||!initialItem.price){
            alert('Missing item name or price!')
        }
        else{
            let temp = [...items]

            temp[itemIndex]=initialItem
            console.log(temp);
            const result = await axios.post(`${apiUrl}/menu/items`,{
                id:menuInf._id,
                items:temp
            })
            console.log(result);
            if(result.data.success){
                setItems(temp)
                setOpenEditItem(false)
                setMenuInf(result.data.data)
                setIntialItem(ITEM)
                window.location.reload()

            }
            else{
                alert('Something went wrong when edit category!' )
            }

        
        }
    }

    // console.log(initialItem,items);

    const [openDeleteItem,setOpenDeleteItem]= useState(false)
    const [deleteItemIndex,setDeleteItemIndex]= useState(-1)

    const handleOpenDeleteItem = (v)=>{
        setOpenDeleteItem(true)
        let temp = [...items]
           
        for(let i=0; i<temp.length; i++){
            if(temp[i].name===v.name) {
                setDeleteItemIndex(i)
            }
        }
    }

    const handleCancelDeleteItem = ()=>{
        setOpenDeleteItem(false)
        setDeleteItemIndex(-1)

    }

    const handleDeleteItem = async()=>{
        console.log(deleteItemIndex)
        let temp = [...items]
        temp.splice(deleteItemIndex, 1)
        console.log(temp);

        const result = await axios.post(`${apiUrl}/menu/items`,{
            id:menuInf._id,
            items:temp
        })
        console.log(result);
        if(result.data.success){
            setItems(temp)
            setOpenDeleteItem(false)
            setDeleteItemIndex(-1)
            setMenuInf(result.data.data)
            setIntialItem(ITEM)
            window.location.reload()

        }
        else{
            alert('Something went wrong when edit category!' )
        }

    }





    //

    useEffect(() => {
        const fetchUser = async()=>{

            const result = await axios.get(`${apiUrl}/auth/menu/id`,{
                params: {id},
            })
            console.log(result)
            if(result.data.success){
                setUser(result.data.data)
                if(result.data.data.menuId){
                    const menuData = await axios.get(`${apiUrl}/menu/id`,{
                        params: {id:result.data.data.menuId},
                    })
                    // console.log(menuData);
                    if(menuData.data.success){
                        setMenuInf(menuData.data.data)
                        setCategory(menuData.data.data.category)
                        setItems(menuData.data.data.items)
                        setSpecial(menuData.data.data.special)
                    }
                }
                else{
                    setIsProfile(true)
                }
            }
        }
        fetchUser()
    },[id])

    const handleClickProfile = () => {
        setIsProfile(true)
    }
    const handleCloseProfile = () => {
        setIsProfile(false)
    }

    const handleIsCategory = () => {
        setIsCreateCategory(true)
        setIsDashboard(false)
        setIsOrder(false)
        setCategoryIndex(-1)
        setIsItem(false)

    }

    const handleDashboard=()=>{
        setIsCreateCategory(false)
        setIsDashboard(true)
        setIsOrder(false)
        setCategoryIndex(-1)
        setIsItem(false)
        setIsSpecialItem(false)

    }

    const handleCreateCategory = () => {
        setCreateCategory(true)
    }
    const handleCloseCreateCategory = ()=>{
        setCreateCategory(false)

    }




    const handleLanguage=(e)=>{
        let temp ={...menuInf}
        temp.language =e.value
        setMenuInf(temp)
    }

    const handleName=(e)=>{
        let temp ={...menuInf}
        temp.name =e.target.value
        setMenuInf(temp)
    }

    const handleDes=(e)=>{
        let temp ={...menuInf}
        temp.des =e.target.value
        setMenuInf(temp)
    }

    const handleIframe=(e)=>{
        let temp ={...menuInf}
        temp.iframe =e.target.value
        setMenuInf(temp)
    }

    const handleLogo=(e)=>{
        let temp ={...menuInf}
        temp.logo =e.target.value
        setMenuInf(temp)
    }

    const handleTel=(e)=>{
        let temp ={...menuInf}
        temp.tel =e.target.value
        setMenuInf(temp)
    }

    const handleTel2=(e)=>{
        let temp ={...menuInf}
        temp.tel2 =e.target.value
        setMenuInf(temp)
    }

    const handleAddress=(e)=>{
        let temp ={...menuInf}
        temp.address =e.target.value
        setMenuInf(temp)
    }

    const handleAddressLink=(e)=>{
        let temp ={...menuInf}
        temp.addressLink =e.target.value
        setMenuInf(temp)
    }

    const handleAddTime=()=>{
        let temp ={...menuInf}
        temp.time.push('')
        setMenuInf(temp)

    }
    const handleRemoveTime=()=>{
        let temp ={...menuInf}
        if(temp.time.length>1){
            console.log(2)
            temp.time.splice(temp.time.length-1,1)
            setMenuInf(temp)

        }
    }
    const handleTime=(e,i)=>{
        let temp ={...menuInf}
        temp.time[i] =e.target.value
        setMenuInf(temp)
    }



    const handleSubmitProfile=async()=>{
        if(!menuInf.name||!menuInf.tel||!menuInf.address||!menuInf.time[0]){
            alert('请把有*标的内容填写')
        }

        const result = await axios.post(`${apiUrl}/menu`,{
            name: menuInf.name,
            tel: menuInf.tel,
            tel2:menuInf.tel2,
            address: menuInf.address,
            time: menuInf.time,
            logo:menuInf.logo,
            category:menuInf.category,
            items:menuInf.items,
            language:menuInf.language,
            addressLink:menuInf.addressLink,
            userId:user._id,
            des:menuInf.des,
            iframe:menuInf.iframe,
            special:menuInf.special,
        })
        console.log(result)
        if(result.data.success){
            setMenuInf(result.data.data)
            await axios.post(`${apiUrl}/auth/menu/update`,
           { id:user._id,
            phone:user.phone,
            password:user.password,
            name:user.name,
            menuId:result.data.data._id
            }
            )
            setIsProfile(false)
        }
        else{
            alert('Something wrong when create merchant!')
        }

    }

    // console.log(category);

    const handleCategoryName=(e)=>{
        let temp ={...initialCategory}
        temp.name =e.target.value
        setInitialCategory(temp)
    }

    const handleCategoryDescription=(e)=>{
        let temp ={...initialCategory}
        temp.des =e.target.value
        setInitialCategory(temp)
    }

    const handleSubmitAddCategory=async()=>{
        if(!initialCategory.name){
            alert('Missing Category Name')
        }
        let temp = [...category]
        temp.push(initialCategory)
        const result = await axios.post(`${apiUrl}/menu/category`,{
            id:menuInf._id,
            category:temp
        })
        console.log(result);
        if(result.data.success){
            setCreateCategory(false)
            setCategory(temp)
            setMenuInf(result.data.data)
            setInitialCategory(CATEGORY)
        }
        else{
            alert('Something went wrong when creating category!' )
        }

    }

  return (
    <div className='user-dashboard'>

        <div className='user-wrap'>
            <div className='user-wrap-top'>
                <div className='user-wrap-top-left'>
                        <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1676999315/menu-master/menu_2_t7grj4.png" alt="" />
                        <h2>Me<span>n</span>uM<span>a</span>ster </h2>
                </div>
                <div className='user-wrap-top-left'>
                        <h4>Welcome, {user.name}</h4>
                </div>

                <div className='user-wrap-top-left'>
                    <button onClick={()=>handleClickProfile()}><img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677000219/menu-master/clerk_ov504i.png" alt="" /> Profile</button>
                </div>

          
                
            </div>
            <hr />


            <div className='user-wrap-mid'>

                <div className='user-wrap-mid-left'>

                    <div className='user-wrap-mid-left-wrap'>
                    <button style={{backgroundColor:isDashboard ? 'lightsteelblue':'',transform:isDashboard ?'scaleX(1.2)':'scaleX(1)',transition:'1s'}} onClick={()=>handleDashboard()}>首页</button>
                    <button style={{backgroundColor:isCreateCategory ? 'lightsteelblue':'',transform:isCreateCategory ?'scaleX(1.2)':'scaleX(1)',transition:'1s'}} onClick={()=>handleIsCategory()}>+分类</button>
                    <button style={{backgroundColor:isOrder ? 'lightsteelblue':'',transform:isOrder ?'scaleX(1.2)':'scaleX(1)',transition:'1s'}} onClick={()=>handleOrder()}>分类排序</button>
                    <button style={{backgroundColor:isSpecialItem ? 'lightsteelblue':'',transform:isSpecialItem ?'scaleX(1.2)':'scaleX(1)',transition:'1s'}} onClick={()=>handleIsSpecialItem()}>每日促销</button>
                    <hr />
                    {
                        category.length>0&&category.map((v,i)=>(
                            <button style={{backgroundColor:i===categoryIndex ? 'lightsteelblue':'',transform:i===categoryIndex?'scaleX(1.2)':'scaleX(1)',transition:'1s'}}  onClick={()=>handleSelectCategory(i)} key={i}>
                                {v.name}
                            </button>
                        ))
                    }

                    </div>

                   

                </div>


                <div className='user-wrap-mid-right'>

                    {
                        isDashboard&&<div className='dashboard-container'>
                                    <h2>{menuInf.name}</h2>
                                    {menuInf.des&&<h6>{menuInf.des}</h6>}
                                {menuInf._id&&<Link to={`/menu/${menuInf._id}`} >浏览菜单页面</Link>}

                               <div className='dashboard-wrap'>
                            <div>
                                <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677014583/menu-master/categories_nwbao8.png" alt="" /><h5>菜单分类 {menuInf.category.length |0}</h5>
                                </div>
                            <div>
                                <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677014583/menu-master/breakfast_xgmwit.png" alt="" /><h5>菜品数量 {menuInf.items.length |0}</h5>
                            </div>
                            <div>
                                <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677014583/menu-master/click_o3pvup.png" alt="" /><h5>页面点击量 {menuInf.views|0}</h5>
                            </div>
                        </div>

                        {menuInf.iframe&&<iframe title='map' src={menuInf.iframe}  frameBorder="0"></iframe>}
                            </div>
                         
                    }

                    {
                        isCreateCategory&&<div className='category-container'>
                            <div className='category-container-wrap'>
                                <button 
                                onClick={()=>handleCreateCategory()} className='category-container-wrap-grid'>
                                    <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677076953/menu-master/plus_vhzkhc.png" alt="" />
                                    <h6>Category</h6>
                                </button>
                                {
                                    category.length>0&&category.map((v,i)=>(
                                        <div key={i}className='category-container-wrap-grid'>

                                            <div>
                                            <img onClick={()=>handleOpenDeleteCategory(i)} id='delete-category' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677082594/menu-master/remove_an4ekm.png" alt="" />
                                            <img onClick={()=>handleOpenEditCategory(i)} id='edit-category' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677082593/menu-master/edit_yq34oh.png" alt="" />
                                            </div>
                                            <h6>{v.name}</h6>
                                            {v.des&&<p>{v.des}</p>}
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    }

                    {
                        isOrder&&<div className='order-container'>

                            <div className='order-container-wrap'>
                            {
                                    category.length>0&&category.map((v,i)=>(
                                        <div key={i}className='category-container-wrap-grid'>

                                            <h6>{i+1}.{v.name}</h6>
                                            <div>
                                               { i!==0&&<img 
                                               onClick={()=>handleLastCategory(i)} src="https://res.cloudinary.com/drmwjovft/image/upload/v1677087210/menu-master/previous_nvlisy.png" alt="" />}
                                                {i!==category.length-1&&<img 
                                                onClick={()=>handleNextCategory(i)}
                                                src="https://res.cloudinary.com/drmwjovft/image/upload/v1677087210/menu-master/next_1_bgxwat.png" alt="" />}
                                            </div>
                                           
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    }

                    {
                        isItem&&<div className='item-container'>
                            <div className='category-container-wrap'>
                                <button 
                                onClick={()=>handleOpenAddItem()} className='category-container-wrap-grid'>
                                    <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677076953/menu-master/plus_vhzkhc.png" alt="" />
                                    <h6>{category[categoryIndex].name}</h6>
                                </button>
                                {
                                    categoryItems.length>0&&categoryItems.map((v,i)=>(
                                        <div key={i}className='category-container-wrap-grid'>

                                            <div>
                                            <img onClick={()=>handleOpenDeleteItem(v)} id='delete-category' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677082594/menu-master/remove_an4ekm.png" alt="" />
                                            <img onClick={()=>handleOpenEditItem(v)} id='edit-category' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677082593/menu-master/edit_yq34oh.png" alt="" />
                                            </div>
                                            <h6>{v.name}</h6>
                                            {v.price&&<p>$ {v.price}</p>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                    {
                        isSpecialItem&&<div className='special-container'>

                            <div className='special-wrap'>
                                  {
                                        special&&special.map((v,i)=>(
                                          <div className='special-wrap-day' key={i}>
                                            <h3>{WEEK[i]}</h3>
                                            <div className='special-wrap-day-items'>
                                                <div className='special-wrap-day-items-scroll'>
                                                {
                                                    v.length>0&&v.map((m,n)=>(
                                                        <div key={n}>
                                                            <img onClick={()=>handleRemoveSpecialItem(i,n)} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />
                                                            <h6>菜名{m.name}</h6>
                                                            <p>原价{m.price}</p>
                                                            <p>促销价{m.sale}</p>
                                                        </div>
                                                    ))
                                                }
                                                <button onClick={()=>handleAddSpecialItem(i)}>Add</button>
                                                </div>
                                               
                                            </div>
                                          </div>
                                        ))
                                    }
                            </div>
                                  
                           
                          
                        </div>
                    }


                </div>

            </div>

        </div>

        <div className='user-profile-wrap' style={{position:'absolute',top:'10vh',scale:isProfile?'1':'0',transition:'1.5s'}}  >
            <img onClick={()=>handleCloseProfile()} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />

            <div className='user-profile-wrap-info'>

                <div className='group'>
                    <input value={menuInf.name} onChange={(e)=>handleName(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >店名*</label>
                </div>

                <div className='group'>
                    <input value={menuInf.des} onChange={(e)=>handleDes(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >店面描述*</label>
                </div>

                <div className='group'>
                    <input value={menuInf.logo} onChange={(e)=>handleLogo(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >logo</label>
                </div>

                <div className='group'>
                    <input value={menuInf.tel} onChange={(e)=>handleTel(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >电话*</label>
                </div>

                <div className='group'>
                    <input value={menuInf.tel2} onChange={(e)=>handleTel2(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >电话2</label>
                </div>

                <div className='group'>
                    <input value={menuInf.address} onChange={(e)=>handleAddress(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >地址*</label>
                </div>

                <div className='group'>
                    <input value={menuInf.addressLink} onChange={(e)=>handleAddressLink(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >谷歌地图链接*</label>
                </div>

                <div className='group'>
                    <input value={menuInf.iframe} onChange={(e)=>handleIframe(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >谷歌地图iframe链接*</label>
                </div>

                <div className='group'>
                   {menuInf.time.map((v,i)=>(
                    <input value={menuInf.time[i]} onChange={(e)=>handleTime(e,i)} key={i} type="text" />
                   ))}

                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >营业时间*</label>
                    <button onClick={()=>handleAddTime()} >+</button>
                    <button onClick={()=>handleRemoveTime()} >-</button>

                </div>


               

                <div className='group2'>
                    <p>语言</p>
                    <Select    
     onChange={(e)=>handleLanguage(e)}
     options={LANGUAGES}             
       menuPortalTarget={document.body}
    menuPosition="fixed"       maxMenuHeight='20vh'           
    />
                </div>

                <button onClick={()=>handleSubmitProfile()} id='user-profile-btn'>提交</button>

            </div>
        </div>

        <div className="category-create-wrap" style={{position:'absolute',top:'30vh',scale:createCategory?'1':'0',transition:'1.5s'}} >

        <img onClick={()=>handleCloseCreateCategory()} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />

        <div className='user-profile-wrap-info'>

                <div className='group'>
                    <input value={initialCategory.name} onChange={(e)=>handleCategoryName(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >Category Name*</label>
                </div>

                <div className='group'>
                    <input value={initialCategory.des} onChange={(e)=>handleCategoryDescription(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >Category Description</label>
                </div>

                <button onClick={()=>handleSubmitAddCategory()}>提交</button>
                </div>

        </div>

        <div className="category-create-wrap" style={{position:'absolute',top:'30vh',scale:isEditCategory?'1':'0',transition:'1.5s'}} >

<img onClick={()=>handleCloseEditCategory()} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />

<div className='user-profile-wrap-info'>

        <div className='group'>
            <input value={editCategory.name} onChange={(e)=>handleEditCategoryName(e)} type="text" />
            <span className="highlight"></span>
        <span className="bar"></span>
            <label >Category Name*</label>
        </div>

        <div className='group'>
            <input value={editCategory.des} onChange={(e)=>handleEditCategoryDescription(e)} type="text" />
            <span className="highlight"></span>
        <span className="bar"></span>
            <label >Category Description</label>
        </div>

        <button onClick={()=>handleSubmitEditCategory()}>提交</button>
        </div>

</div>

        <div className="category-create-wrap" style={{position:'absolute',top:'30vh',scale:isDeleteCategory?'1':'0',transition:'1.5s'}} >
            {category.length>0&&<h5>确定删除 {deleteCategory.name} 吗</h5>}
            <div className='delete-category-wrap'>
            <button onClick={()=>handleSubmitDeleteCategory()}>删除</button>
                <button onClick={()=>handleCancelDeleteCategory()}>取消</button>
            </div>
               
        </div>

        <div className='user-profile-wrap' style={{position:'absolute',top:'10vh',scale:openItem?'1':'0',transition:'1.5s'}}  >
            <img onClick={()=>handleCloseAddItem()} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />

            <div className='user-profile-wrap-info'>

                <div className='group'>
                    <input value={initialItem.name} onChange={(e)=>handleItemName(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >菜名*</label>
                </div>
                <div className='group'>
                    <input value={initialItem.price} onChange={(e)=>handleItemPrice(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >价格*</label>
                </div>

                <div className='group'>
                    <input value={initialItem.des} onChange={(e)=>handleItemDes(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >菜描述</label>
                </div>

               

                <div className='group'>
                    <input value={initialItem.img} onChange={(e)=>handleItemImg(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >图片链接</label>
                </div>

                <div className='group2'>
                    <p>口味是否辣</p>
                    <Select    
     onChange={(e)=>handleSpicy(e)}
     options={SPICY}             
       menuPortalTarget={document.body}
    menuPosition="fixed"       maxMenuHeight='20vh'           
    />
                </div>

                <button onClick={()=>handleSubmitAddItem()} id='user-profile-btn'>提交</button>

            </div>
        </div>

        <div className='user-profile-wrap' style={{position:'absolute',top:'10vh',scale:openEditItem?'1':'0',transition:'1.5s'}}  >
            <img onClick={()=>handleCloseEditItem()} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />

            <div className='user-profile-wrap-info'>

                <div className='group'>
                    <input value={initialItem.name} onChange={(e)=>handleItemName(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >菜名*</label>
                </div>
                <div className='group'>
                    <input value={initialItem.price} onChange={(e)=>handleItemPrice(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >价格*</label>
                </div>

                <div className='group'>
                    <input value={initialItem.des} onChange={(e)=>handleItemDes(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >菜描述</label>
                </div>

               

                <div className='group'>
                    <input value={initialItem.img} onChange={(e)=>handleItemImg(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >图片链接</label>
                </div>

                <div className='group2'>
                    <p>口味是否辣</p>
                    <Select    
     onChange={(e)=>handleSpicy(e)}
     options={SPICY}             
       menuPortalTarget={document.body}
    menuPosition="fixed"       maxMenuHeight='20vh'           
    />
                </div>

                <button onClick={()=>handleSubmitEditItem()} id='user-profile-btn'>提交</button>

            </div>
        </div>

        <div className="category-create-wrap" style={{position:'absolute',top:'30vh',scale:openDeleteItem?'1':'0',transition:'1.5s'}} >
            {items.length>0&&openDeleteItem&&<h5>确定删除 {items[deleteItemIndex].name} 吗</h5>}
            <div className='delete-category-wrap'>
            <button onClick={()=>handleDeleteItem()}>删除</button>
                <button onClick={()=>handleCancelDeleteItem()}>取消</button>
            </div>
               
        </div>

        <div className='user-profile-wrap' style={{position:'absolute',top:'10vh',scale:isAddSpecial?'1':'0',transition:'1.5s'}}  >
            <img onClick={()=>handleCloseAddSpecialItem()} id='close-btn' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677002156/menu-master/close_2_yi4qd9.png" alt="" />

            <div className='user-profile-wrap-info'>

                <div className='group'>
                    <input value={specialItem.name} onChange={(e)=>handleSpecialItemName(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >菜名*</label>
                </div>
                <div className='group'>
                    <input value={specialItem.des} onChange={(e)=>handleSpecialItemDes(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >菜描述</label>
                </div>
                <div className='group'>
                    <input value={specialItem.price} onChange={(e)=>handleSpecialItemPrice(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >原价*</label>
                </div>
                <div className='group'>
                    <input value={specialItem.sale} onChange={(e)=>handleSpecialItemSale(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >促销价*</label>
                </div>

                <div className='group'>
                    <input value={specialItem.img} onChange={(e)=>handleSpecialItemImg(e)} type="text" />
                    <span className="highlight"></span>
                <span className="bar"></span>
                    <label >图片链接</label>
                </div>

               

                <button onClick={()=>handleSubmitSpecialItem()} id='user-profile-btn'>提交</button>

            </div>
        </div>
        
    
    </div>
  )
}


