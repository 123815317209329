import React,{useState,useEffect} from 'react'
import {Link,Navigate,useNavigate} from 'react-router-dom'
import axios from 'axios'
import './index.scss'


const apiUrl ='https://metaserverapp.com'
// const apiUrl = 'http://localhost:5000'

export default function AdminImg() {

  const initial = {
    cn:'',
    en:'',
    img:''
  }

  const menuToken = window.localStorage.getItem('menuToken')

  const [isCreate,setIsCreate]=useState(false)
  const [picture,setPicture] = useState(initial)
  const [page,setPage] = useState(1)
  const [total,setTotal]=useState(0)
  const [totalPage,setTotalPage] = useState(0)
  const [pictures,setPictures]=useState([])
  const [isDelete,setIsDelete]=useState(false)
  const [preview,setPreview]=useState('')
  const [img,setImg] = useState(new FormData())

  const handleCn = (e) => {
    const temp ={...picture}
    temp.cn=e.target.value
    setPicture(temp)
  }

  const handleEn = (e)=>{
    const temp = {...picture}
    temp.en = e.target.value
    setPicture(temp)
  }


  const handleImg = async(e)=>{
  
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('menu', file)
    const temp = URL.createObjectURL(file)
    console.log(formData)
    console.log(file)
    console.log(temp)
    setPreview(temp)
    setImg(formData)

    // const imgData = await axios.post(`${apiUrl}/picture/upload`,formData)
    //     console.log(imgData)
  }
  

  console.log(pictures)

  const handleSubmit = async()=>{
    console.log(img)

    if(!picture.cn||!picture.en){
      alert('Missing name or img')
    }
    else{
      const imgData = await axios.post(`${apiUrl}/picture/upload`,img)
        console.log(imgData)
        if(imgData.data.success){
          const imgUrl = imgData.data.data
          const result = await axios.post(`${apiUrl}/picture/create`,{
            cn:picture.cn,
            en:picture.en,
            img:imgUrl
          })
          console.log(result)
          if(result.data.success){
            alert('上传成功!')
            window.location.reload()
          }
          else{
            alert('上传失败！')
            window.location.reload()
          }
        }
      
    }

  }

  const [index,setIndex]=useState(-1)
  
  const handleDelete=(i)=>{
    setIsDelete(true)
    setIndex(i)
  }
  const handleCancelDelete=()=>{
    setIsDelete(false)
    setIndex(-1)
  }
  const handleSubmitDelete=async(v)=>{
   console.log(v)
      const result = await axios.post(`${apiUrl}/picture/delete`,{id:v._id})

    console.log(result)
    if(result.data.success){
      alert('删除成功')
      window.location.reload()
    }

  }

  useEffect(()=>{
    
    const fetchPictures = async()=>{
      const result = await axios.get(`${apiUrl}/picture/all`,{params:{page:1}})
      console.log(result)
      if(result.data.success){
        setPictures(result.data.data[0].result)
        const temp = result.data.data[0].total[0].total
        setTotal(result.data.data[0].total[0].total)
        const temp2 = Math.ceil(temp/50)
        setTotalPage(temp2)
      }
      else{
        alert('Server Error!')
      }
    }
    fetchPictures()

  },[])

  useEffect(()=>{
    
    const fetchPictures = async()=>{
      const result = await axios.get(`${apiUrl}/picture/all`,{params:{page:page}})
      console.log(result)
      if(result.data.success){
        setPictures(result.data.data[0].result)
        const temp = result.data.data[0].total[0].total
        setTotal(result.data.data[0].total[0].total)
        const temp2 = Math.ceil(temp/50)
        setTotalPage(temp2)
      }
      else{
        alert('Server Error!')
      }
    }
    fetchPictures()

  },[page])


 

  const handleCreate=()=>{
    setIsCreate(true)
  }
  const handleCloseCreate=()=>{
    setIsCreate(false)
    setPreview('')
    setImg(new FormData())
    setPicture(initial)
  }




  const handleNextPage = ()=>{
    setPage(page+1)
    const wrap =document.getElementsByClassName('admin-wrap-users')[0]
    wrap.scrollTop = 0
  }
  const handleLastPage = ()=>{
    setPage(page-1)
    const wrap =document.getElementsByClassName('admin-wrap-users')[0]
    wrap.scrollTop = 0

  }


 


  return (

    menuToken ? 
    <div className='admin-container'>

      <h2>餐馆menu大师图片管理后台</h2>

      <div className='admin-wrap'>

      <div className='admin-wrap-top'>
      <Link to='/admin/dashboard'>
        <button>图片库管理</button>
        </Link>
        <button onClick={()=>handleCreate()}>
          创建图片
        </button>
     
      </div>

      <div className='admin-wrap-users'>
      <table>
            <thead>
                <tr>
                  <td>
                    中文名
                  </td>
                  <td>
                    英文名
                  </td>
                  <td>
                    图片
                  </td>
               
                  <td>
                    删除
                  </td>
                </tr>
            </thead>
            <tbody>
            
                {
                  pictures.length >0&&pictures.map((v,i)=>(
                    <tr key={i}>
                    <td >
                      {v.cn}
                    </td>
                    <td>
                      {v.en}
                    </td>
                    <td>
                      <img src={v.img} alt="" />
                    </td>
                
                    {i===index?<td>
                      {isDelete? <><button style={{backgroundColor:'red'}} onClick={()=>handleSubmitDelete(v)}>确定删除</button> <button onClick={()=>handleCancelDelete()}>取消</button></>  : <button onClick={()=>handleDelete()}>删除</button>}
                    </td>: <td>
                    <button onClick={()=>handleDelete(i)}>删除</button>
                    </td>}
                    </tr>

                  ))
                }
               
            </tbody>


        </table>

      </div>
      <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
      </div>

      <div className='admin-create' style={{position:'absolute',top:'10vh',scale:isCreate?'1':'0',transition:'1s'}}  >
      <img onClick={()=>handleCloseCreate()} id='close-icon' src="https://res.cloudinary.com/dvr397xda/image/upload/v1666643028/remove_dywpuk.png" alt="" />

      <div className='admin-create-item'>
            <label >菜品中文名</label>
            <input onChange={(e)=>handleCn(e)} value={picture.cn} type="text" />
          </div>


      <div className='admin-create-item'>
            <label >菜品英文名</label>
            <input onChange={(e)=>handleEn(e)} value={picture.en} type="text" />
          </div>

          <div className='admin-create-item'>
            <label >上传菜品图片</label>
            <input onChange={(e)=>handleImg(e)} value={picture.img} type="file" />
            {picture.img&&<img href={picture.img} alt=""/>}
            {
              preview&&<div className='preview-img'>
                <img src={preview} alt="" />
              </div>
            }
          </div>

            <button onClick={()=>handleSubmit()}>上传</button>
          </div>


    </div>
    :<Navigate to='/admin/login' />
    
  )
}
