import React,{useState,useEffect} from 'react'
import { useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';

import axios from 'axios'
import './index.scss'

const apiUrl ='https://metaserverapp.com'


export default function Menu() {

    const WEEK = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ]


    
    const today = new Date().getDay()

    const{ id } = useParams();
    const [menu,SetMenu]=useState({})
    const [load,setLoad] = useState(false)
    const [special,setSpecial] = useState([])
    console.log(today)
    // Select Category

    const [selectCategoryIndex,setSelectCategoryIndex]=useState(-1)
    const [items,setItems] = useState([])

    const handleSelectCategory = (i)=>{
        setSelectCategoryIndex(i)
        const tempName = menu.category[i].name
        let arr = []
        let temp = [...menu.items]
        for(let j=0; j<temp.length; j++) {
            if(temp[j].category === tempName) {
                arr.push(temp[j])
            }
        }
        setItems(arr)
        
    }
    const handleAll = ()=>{
        setSelectCategoryIndex(-1)
        setItems(menu.items)
    }

    const [isSpecial,setIsSpecial]=useState(false)

    const handleSpecial=()=>{
        setIsSpecial(!isSpecial)
    }





    useEffect(() => {

        const fetchMenu = async () => {

            const result = await axios.get(`${apiUrl}/menu/id`,{params:{id}})
            console.log(result);
            if(result.data.success){
                SetMenu(result.data.data);
                setItems(result.data.data.items)
                setLoad(true)
           
                setSpecial(result.data.data.special[today])

            }
            else{
                alert('Someting went Wrong!')
            }
        }
        const viewMenu = async()=>{
            const result = await axios.post(`${apiUrl}/menu/view`,{id})
            console.log(result);
            if(result.data.success){
                SetMenu(result.data.data);
            }
            else{
                alert('Someting went Wrong!')
            }
        }
        fetchMenu()
        viewMenu()
    },[id])

    // shopping cart

    const [cart,setCart] = useState([])
    const [isCart,setIsCart] = useState(false)

    const handleAddItem = (v)=>{
        let temp =[...cart]
        temp.push(v)
        setCart(temp)
    }

    const handleOpenCart =()=>{
        setIsCart(true)
    }
    const handleCloseCart =()=>{
        setIsCart(false)
    }
   
    const handleRemoveItem = (i)=>{
        let temp =[...cart]
        temp.splice(i, 1)
        setCart(temp)
    }

    console.log(items)
    useEffect(()=>{
        if(cart.length ===0){
            setIsCart(false)
        }

    },[cart])

    



console.log(special)

  return (
    <>
   {load?<div className='menu-container'>
   <Helmet
      title={menu.name}
      meta={[
        {
          property:'og:image',
          content:menu.logo?menu.logo:'https://res.cloudinary.com/drmwjovft/image/upload/v1676999315/menu-master/menu_2_t7grj4.png'
        },
       
        {
          name: menu.name ?menu.name:'' ,
          content: menu.des ? menu.des :'',
        },
      ]}
    
    />

        <div className='menu-wrap-top'>
            {menu.logo&&<img src={menu.logo} alt="" />}
            <h1>{menu.name}</h1>
            {menu.des&&<p>{menu.des}</p>}
        </div>
        {special.length>0&&<div className='special-wrap'>
            <div className='special-wrap-top' onClick={()=>handleSpecial()}>
            <h4>{WEEK[today]}'s Special Deal</h4>
            <img src={isSpecial ? 'https://res.cloudinary.com/drmwjovft/image/upload/v1677621716/up-arrow_lvhu07.png':'https://res.cloudinary.com/drmwjovft/image/upload/v1677621716/arrow-down-sign-to-navigate_qqbewi.png'} alt="" />
            </div>

            {isSpecial&&<div  className='special-items-wrap'>
                {
                    special.length>0&&special.map((v,i)=>(
                        (v.img?<div className='menu-item-with-img' key={i}>
                            <div 
                            style={{backgroundImage:`url(${v.img})`}} className='menu-item-single-img'>

                            </div>
                            <div className='menu-item-single-right'>
                            <h2>{v.name} {v.spicy?'🌶':''}</h2>
                            <p className='des'>{v.des}</p>
                            <div>
                                <p className='ori-price'>${v.price}</p>
                                <button onClick={()=>handleAddItem(v)}>add</button>
                            </div>
                            </div>

                        </div>:<div className='menu-item-single' key={i}>

                            <div className='menu-item-single-left'>
                            <h2>{v.name}{v.spicy?'🌶':''}</h2>
                            <p className='des'>{v.des}</p>
                            <p className='ori-price'>${v.price}</p>
                            <p className='sale-price'>${(v.price*0.8).toFixed(2)}</p>
                            </div>

                            <div className='menu-item-single-btn'>
                            <button onClick={()=>handleAddItem(v)}>add</button>

                            </div>
                        
                        <div>

                        </div>

                    </div>)
                    ))
                }

            </div>}
        </div>}
        <div className='menu-category-container'>

            <div className='menu-category'>
            <div className='menu-category-wrap'>
                <button onClick={()=>handleAll()} style={{backgroundColor: selectCategoryIndex===-1? '#F8B03C':''}}>All</button>
                {
                    menu.category.length>0&&menu.category.map((v,i)=>(
                        <button onClick={()=>handleSelectCategory(i)} style={{backgroundColor: selectCategoryIndex===i? '#F8B03C':''}}  key={i}>{v.name}</button>
                    ))
                }
               

            </div>

            </div>
            {selectCategoryIndex!==-1&&<p className="category-des">{menu.category[selectCategoryIndex].des}</p>}
           
        </div>
        <div className='menu-item-container'>
            <div className='menu-item-wrap'>
                {
                    items.length>0&&items.map((v,i)=>(
                       (v.img?<div className='menu-item-with-img' key={i}>
                            <div 
                            style={{backgroundImage:`url(${v.img})`}} className='menu-item-single-img'>

                            </div>
                            <div className='menu-item-single-right'>
                            <h2>{v.name} {v.spicy?'🌶':''}</h2>
                            <p className='des'>{v.des}</p>
                            <div>
                                <p>${v.price}</p>
                                <button onClick={()=>handleAddItem(v)}>add</button>
                            </div>
                            </div>

                        </div>:<div className='menu-item-single' key={i}>

                            <div className='menu-item-single-left'>
                            <h2>{v.name}{v.spicy?'🌶':''}</h2>
                            <p className='des'>{v.des}</p>
                            <p>${v.price}</p>

                            </div>

                            <div className='menu-item-single-btn'>
                            <button onClick={()=>handleAddItem(v)}>add</button>

                            </div>
                        
                        <div>

                        </div>

                    </div>)
                    ))
                }

            </div>

        </div>

        <div className='menu-info'>

            <h4>Business Hour</h4>
            {
                menu.time.length>0&&menu.time.map((v,i)=>(
                    <h5 key={i}>
                        {v}
                    </h5>
                ))
            }
            <h6>Tel:+1 <a href={`tel:+1${menu.tel}`}>{menu.tel}</a> </h6>
            {menu.tel2&&<h6>Tel:+1 <a href={`tel:+1${menu.tel2}`}>{menu.tel2}</a> </h6>}
            <h6>Address:
                <a href={menu.addressLink}>                {menu.address}
</a>
            </h6>
            <iframe title='map'  src={menu.iframe}  width="94%" height="300"></iframe>

        </div>

        {cart.length>0&&<div style={{scale:isCart ? '0':'1',transition:'1s'}}  onClick={()=>handleOpenCart()} className='cart-btn'>
                        <div>

                <p className="pop-n">{cart.length}</p>
                </div>
            <img src="https://res.cloudinary.com/drmwjovft/image/upload/v1677256039/menu-master/restaurant-cutlery-circular-symbol-of-a-spoon-and-a-fork-in-a-circle_aqemmj.png" alt="" />
           
        </div>}

        <div style={{top:isCart? '40vh':'200vh',scale:isCart ? '1' :'0',transition:'1.5s'}} className='cart-container'>
        <div className='menu-item-wrap'>
                {
                    cart.length>0&&cart.map((v,i)=>(
                       (v.img?<div className='menu-item-with-img' key={i}>
                            <div 
                            style={{backgroundImage:`url(${v.img})`}} className='menu-item-single-img'>

                            </div>
                            <div className='menu-item-single-right'>
                            <h2>{v.name}</h2>
                            <p className='des'>{v.des}</p>
                            <div>
                                <p>${v.price}</p>
                                <button onClick={()=>handleRemoveItem(i)}><img src='https://res.cloudinary.com/drmwjovft/image/upload/v1677265770/menu-master/trash-can_k7almq.png' alt=''/></button>
                            </div>
                            </div>

                        </div>:<div className='menu-item-single' key={i}>

                            <div className='menu-item-single-left'>
                            <h2>{v.name}</h2>
                            <p className='des'>{v.des}</p>
                            <p>${v.price}</p>

                            </div>

                            <div className='menu-item-single-btn'>
                            <button onClick={()=>handleRemoveItem(i)}><img src='https://res.cloudinary.com/drmwjovft/image/upload/v1677265770/menu-master/trash-can_k7almq.png' alt='' /></button>

                            </div>
                        
                        <div>

                        </div>

                    </div>)
                    ))
                }

            </div>

        </div>


        <div onClick={()=>handleCloseCart()} style={{top:isCart? '0':'-100vh',transition:'1.5s'}}  className='cart-click-container'>

        </div>


    </div> : <div><img id='loading' src="https://res.cloudinary.com/drmwjovft/image/upload/v1677187984/menu-master/cartoon-snail-loading-loading-gif.gifanimation_2734139.png_bw700_kkfdkl.gif" alt="" /></div>}

    </>
  )
}


